import React from "react";
import "./Hero.css";
import award from "../../../Assets/austin-award.png";

const Hero = () => {
  return (
    <div className="hero-img container">
      <div className="hero-content">
        <h1 className="heading-primary">
          Welcome to <span> Austin's Bar-B-Que</span>
        </h1>
        <p className="text-white">Premium Texas BBQ Catering and Dine-In</p>
        <div className="award">
          <img src={award} alt="award" />
          <p className="text-white">Winner of the Best BBQ</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
